.travelDocFormItemRow{
	vertical-align:middle;
	background-color: rgba(221, 237, 255, 0.349);
	font-weight: 500;
	text-align: center;
	min-height: fit-content;
	border-left: 1px solid;
	border-right: 1px solid;
	border-bottom: 1px solid;
	border-color: rgba(82, 148, 224, 0.678);
	color: rgb(0 0 0 / 73%);
}
.travelDocFormItemCol{
	margin-bottom: 1.5rem;
	border-style :hidden;
	/* border-right: 0.15rem  dashed;
	border-left: 0.15rem dashed; */
	border-color:rgba(228, 241, 255, 0.349);
	
	opacity: 0.99;
	text-align: center;
}
.travelDocFormItemCol:hover{
	border-right: 0.15rem solid;
	border-left: 0.15rem solid;
	border-color: rgba(82, 148, 224, 0.678);
	transition: 100ms;
}

