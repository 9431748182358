.notFoundContainer{
    padding-top: 5%;
    text-align: center;
    width: 80%;
    margin: auto;
 }
 .notFoundHeader{
    padding-bottom: 0%;
    font-weight: bolder;
    font-size: 12rem;
    margin-bottom: 0%;
 }
 .notFoundUnderHeader{
    font-weight: bold;
    padding-bottom: 1%;
 }
 .notFoundText{
    font-size: larger;
    padding-bottom: 5%;
 }
 .notFoundButton{
	min-width: fit-content !important;
    width: 15vw;
    height: 6vh;
    font-size: 1rem !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
 }