.headerCont{
    width:100%;
    padding: 2% !important;
}
.header-logo-container{
    margin-left: 6%;
	margin-right: 6%;
    width: auto;
}
.logout-brand{
	float:right;
    padding-right: 6%;
	color: gray !important ;
	font-size: 1.5rem !important;
	margin: 0;
}
.logout-brand:hover{
	color-interpolation-filters: linearRGB;
	color : black !important;
	transition: 0.7s; 
}
 .listItems{
	justify-content: right;
	flex: 1;
}
.nav-item{
	padding-right: 12%;
}

.header-realization{
	float: left;
    font-size: 1.6rem !important;
	margin: 0;
}
.header-logo{
    height: 80px;
	background-color: transparent;
}
.header-logo-container :hover .header-logo{
	filter: drop-shadow(0 0 1rem rgba(97, 176, 228, 0.411));
	transition: 0.5s;
}

