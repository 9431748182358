  .edit_form_contaner
  {
      width: 100%;
      margin: auto;
  }
  .inputEdit{
    text-align:center;
    display: block;
    margin-bottom: 0.25rem;
    font-size: 14pt;
  }
  .submitEdit{
	min-width: fit-content !important;
    width: 16vw;
    height: 7vh;
    font-size: 1.7vh !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
  }
  .submitEditContainer{
    padding-top: 3rem;
  }

   .main-edit-text{
        padding-bottom: 3rem;
   }