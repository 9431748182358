.accountActivationText{
    font-size: larger;
    padding-bottom: 3%;
    margin: auto;
    width: 50%;
}

.resetActivationCode:hover{
    color: rgb(80, 87, 87);
    background-color: rgb(211, 211, 212);
}

.sendCodeAgainText{
    text-align: center;
    font-style: italic;
    text-decoration: underline;
    font-size:large;
    opacity: 0.8;
    width: fit-content;
    margin: auto;
}
.sendCodeAgainButton{
    text-align: center;
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
    font-size:large;
    width: fit-content;
    margin: auto;
    border: none;
    opacity: 0.7;
    background-color: white;
}