
.formInputLabel{
    font-size: larger;
    text-align:left;
    margin: default auto;
}

.formInputContainer{
    text-align:left;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    margin:  default auto;
    position: relative;
}
.formInput{
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
.formInputShowHideButton{
  position: absolute !important;
  right: 30px;
}