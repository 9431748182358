.login_container{
    text-align: center;
    align-items: center;
    padding-top: 7%;
}
.log_success_header{
    padding-bottom: 0.5rem;
    font-size: xx-large;
}
.submitLog{
	min-width: fit-content !important;
    width: 16vw;
    height: 6vh;
    font-size: 1.7vh !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
}
.login_text{
    font-size: larger;
}
.passwordRestoreButtonBox{
	width: 20rem;
	margin: auto; 
	text-align: left; 
	padding: 0;
	opacity: 0.6;
}
.passwordRestoreButton{
	padding-left: 0;
    text-align: left;
    /* font-style: italic; */
    font-weight: normal;
    font-size: medium;
    width: fit-content;
    margin: auto;
    border: none;
    background-color: white;
}
.passwordRestoreButtonBox:hover{
	text-decoration: underline;
	opacity: 1 !important;
}
.passwordRestoreButton:hover{
	text-decoration: underline;
}