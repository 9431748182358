.registrationSuccessContainer{
    padding-top: 1rem;
    text-align: center;
    width: 80%;
    margin: auto;
 }
 .registrationSuccessHeader{
    padding-bottom: 5%;
 }
 .registrationSuccessText{
    font-size: larger;
    padding-bottom: 5%;
 }