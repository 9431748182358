
body {
	height: 100vh;
}
#root	 {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.footer{
	flex: 0 0 auto;
 }
 .header{
  padding-bottom:3rem;
 }
 .appContainer{
	margin: 3vh 0vh;
	min-height: 50%;
	flex: 1 0 auto;
 }

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     /* display: none; <- Crashes Chrome on hover */
     -webkit-appearance: none;
     margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
 }
 
 input[type=number] {
     -moz-appearance:textfield; /* Firefox */
	 appearance: textfield;
 }