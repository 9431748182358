.printButton{
	min-width: fit-content !important;
    width: 16vw;
    height: 7vh;
    font-size: 1rem !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
}
.printButtonContainer{
	display: flex !important;
  	justify-content: space-evenly;
	width: 70%;
	margin:  1rem auto ;
}