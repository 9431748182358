.contractAddedActionButton{
	min-width: fit-content !important;
    width: 16vw;
    height: 7vh;
    font-size: 1.7vh !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
	margin: auto !important;
}
.ContractAddedInfo{
	display: flex;
	height: 100%;
	vertical-align: middle;
	
}