.createContract_form_contaner
{
    width: 100%;
    margin: auto;
}
.inputCreateContract{
  text-align:center;
  display: block;
  margin-bottom: 0.25rem;
  font-size: 14pt;
}
.submitCreateContract{
	min-width: fit-content !important;
	width: 16vw;
	height: 7vh;
	font-size: 1rem !important; 
	font-weight: bold !important; 
	letter-spacing:0.1ex !important;
	margin: auto !important;
}
.main-createContract-text{
	padding-bottom: 0rem;
}

.checkBoxContract{
min-height:5%;
width: calc(2.25rem + 2px)!important;
}
.checkBoxContractLabel{
text-align:start;
text-decoration: underline;
}


.inputRegCheck{
align-content: center;
padding-left: 2%;
}
.contractLabel-rigth{
text-align: right !important;
}
#createContract-form{
margin-bottom: 5%;
}
.contractFormCheckBoxList{
margin-bottom: 0%;
padding-left: 0%;
}
.contractFormInfoText{
text-indent: 4%;
}

.sumBox{
font-size: large !important;
}