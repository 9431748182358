.travelDocFormFooter{
	background-color: rgba(221, 237, 255 , 0.51);
	text-align: center;
	min-height: fit-content;
	
	height: fit-content;
	border-bottom-right-radius: 1.75rem !important; 
	border-bottom-left-radius: 1.75rem !important;
	border: 0.15rem;	
	border-style : solid;
	border-top-style: none;
	
	border-color: rgba(82, 148, 224, 0.678);
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: rgb(9 10 59 / 73%);
}

.travelDocFormFooterItem{
	margin-bottom: 0;
}
.ItemBorder:hover{
	border-right: 0.15rem solid;
	border-left: 0.15rem solid;
	border-color: rgba(82, 148, 224, 0.678);
	transition: 100ms;
}