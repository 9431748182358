.tabHeader{
	font-size: medium !important;
	font-weight: bold !important;
}

.submitNewCathegory{
	min-width: fit-content !important;
    width: 18rem;
    height: 3.5rem;
    font-size: medium !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
}
.switchTD{
	font-size: larger !important; 
    font-weight: bold !important; 
}