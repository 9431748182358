.row{
	padding-top: 1.5rem;
}
.reg_form_contaner{
	width: 100%;
	margin: auto;
}
.submitReg{
	min-width: fit-content !important;
    width: 16vw;
    height: 6vh;
    font-size: 1.7vh !important; 
	font-weight: bold !important; 
	letter-spacing:0.1ex !important;
}
.main-reg-text{
	padding-bottom: 0rem;
}
.registration_text{
	font-size: larger;
	padding-bottom: 2%;
}
.not-show{
display: none;
}
