.travelDocFormHeader{
	background-color: rgba(221, 237, 255 , 0.51);
	
	font-weight: 700;
	text-align: center;
	min-height: fit-content;
	
	height: fit-content;
	border-top-right-radius: 1.75rem !important; 
	border-top-left-radius: 1.75rem !important;
	border: 0.15rem;	
	border-style :double;
	border-color: rgba(82, 148, 224, 0.678);
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: rgb(9 10 59 / 73%);
}
.travelDocFormHeaderItem{
	margin-bottom: 0;
	display:flex;
	justify-content: center;
	font-size: medium;
}
.travelDocFormHeader .textSmall{
	font-size: medium;
}
.ItemBorder:hover{
	border-right: 0.15rem solid;
	border-left: 0.15rem solid;
	border-color: rgba(82, 148, 224, 0.678);
	transition: 100ms;
}
.sortOrderSymbol{
	
	font-size: 1rem;
	color: rgba(82, 148, 224, 0.678);
}