.resetSubmitCancelForm{
    color: rgb(80, 87, 87);
    background-color: rgb(221, 230, 230);
    width: 18rem;
    height: 3rem;
    font-size:large !important; 
    font-weight: 700 !important;
    text-transform: none !important;
    letter-spacing:0.2ex !important;
    padding-top: 0%;
    padding-bottom: 0%;
}
/* .resetSubmitCancelForm:hover{
    color: rgb(5, 5, 5); 
     font-weight: bold !important;
    color: rgb(80, 87, 87);
    background-color: rgb(211, 211, 212);
} */

.submitSubmitCancelForm{
	min-width: fit-content !important;
    width: 18rem;
    height: 3rem;
    font-size: medium !important; 
    font-weight: bold !important; 
    letter-spacing:0.1ex !important;
}

.colReset-SubmitCancelForm{
    width: 50%;
    text-align: right;
    padding-right: 3%;
}
.colSubmit-SubmitCancelForm{
    width: 50%;
    text-align: left;
    padding-left: 3%;
}

